<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>AI审单服务登录</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-item>
        <ion-label position="stacked">请输入手机号</ion-label>
        <div style="display: inline-flex; flex-direction: row; justify-content: space-between;width: 100%;">
          <ion-input :value="mobile" @ionInput="mobile = $event.target.value;" type="number"></ion-input>
        </div>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">请输入密码</ion-label>
        <ion-input :value="password" @ionInput="password = $event.target.value;" type="password"
                   placeholder=""></ion-input>
      </ion-item>
      <ion-button style="margin-top: 50px;" expand="block" @click="login">登录</ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonButton, IonContent, IonHeader, IonInput, IonItem, IonLabel, IonPage, IonTitle, IonToolbar} from '@ionic/vue';
import {setToken} from "@/utils/auth";
import {login} from "../api/user";

export default {
  name: 'Login',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonLabel,
    IonInput,
    IonButton,
    IonItem
  },
  data() {
    return {
      mobile: undefined,
      password: undefined
    }
  },
  methods: {
    login: function () {
      login({mobilePhone: this.mobile, password: this.password}).then(res => {
        console.log(res);
        if (res.code === 200) {
          alert(res.msg);
          setToken(res.data.token);
          this.$router.push({
            path: '/tabs/tab1',
          });
        }
      }).catch(res => {
        alert(res.data.msg);
      });
    }
  }
}
</script>
<style lang="css">
ion-back-button {
  display: block;
}
</style>
