import request from '../utils/request'

/**
 * 用户登录
 * @param params object 用户登陆信息
 */
export const login = (params: any) => {
    return request({
        url: '/save/open/login',
        method: 'post',
        data: params
    })
}

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
    return request({
        url: '/save/open/info',
        method: 'get',
    })
}
